* {
    box-sizing: border-box;
}

html {
    background: #000;
}

html, body, #gigover-root {
    width: 100%;
    height: 100%;
}

#gigover-root {
    max-width: 100%;
    overflow-x: hidden;
}

body {
    background: #fff;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
    text-decoration: none;
}

h1, h2, h3, h4, h5, p {
    margin: 0;
}

p {
    color: #838894;
    line-height: 22px;
}