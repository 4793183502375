.mentions {
    margin: 1em 0;
}

.mentions--singleLine .mentions__control {
    display: inline-block;
    width: 130px;
}
.mentions--singleLine .mentions__highlighter {
    padding: 1px;
    border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
    padding: 1px;
    border: 2px inset;
}

.mentions--multiLine .mentions__control {
    border: 1px solid transparent;
    font-size: 14pt;
}
.mentions--multiLine .mentions__highlighter {
    border: 1px solid transparent;
    padding: 9px;
    min-height: 63px;
}

.mentions--multiLine .mentions__input {
    border: 1px solid var(--chakra-colors-green-500);
    border-radius: 8px;
    padding: 9px;
    outline: 0;
}

.mentions__suggestions__list {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 14pt;
}

.mentions__suggestions__item {
    padding: 5px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
    background-color: #82d4d7;
}

.mentions__mention, .mentions__highlighter strong {
    position: relative;
    z-index: 1;
    background: #000;
    color: #fae44d;
    pointer-events: none;
    display: inline-block;
}
